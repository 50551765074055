<template>
  <div class="totalViewPage">
    <ul class="flexColumn dataColumn">
      <li style="--bkgColor: rgb(151,151,253);">
        <h3>仓库数量</h3>
        <div class="flexColumn dataDetail">
          <dl>
            <dd>{{ tenantEnabled | fixed }}</dd>
            <dt>已启用</dt>
          </dl>
          <dl>
            <dd>{{ tenantDisabled | fixed }}</dd>
            <dt>未启用</dt>
          </dl>
        </div>
      </li>
      <li style="--bkgColor: rgb(148,185,255);">
        <h3>仓库容量（立方米）</h3>
        <div class="flexColumn dataDetail">
          <dl>
            <dd>{{ usedVolume | fixed(1) }}</dd>
            <dt>已用容量</dt>
          </dl>
          <dl>
            <dd>{{ remainVolume | fixed(1) }}</dd>
            <dt>剩余容量</dt>
          </dl>
        </div>
      </li>
      <li style="--bkgColor: rgb(129,211,248);">
        <h3>货主数量</h3>
        <div class="flexColumn dataDetail">
          <dl>
            <dd>{{ ownerCount | fixed }}</dd>
            <dt>总数</dt>
          </dl>
          <dl>
            <dd>{{ ownerCountNew | fixed }}</dd>
            <dt>本月新增</dt>
          </dl>
        </div>
      </li>
      <li style="--bkgColor: rgb(255,176,74);">
        <h3>商品SPU数量</h3>
        <div class="flexColumn dataDetail">
          <dl>
            <dd>{{ spuCount | fixed }}</dd>
            <dt>总数</dt>
          </dl>
          <dl>
            <dd>{{ spuCountNew | fixed }}</dd>
            <dt>本月新增</dt>
          </dl>
        </div>
      </li>
      <li style="--bkgColor: rgb(255,200,51);">
        <h3>商品SKU数量</h3>
        <div class="flexColumn dataDetail">
          <dl>
            <dd>{{ skuCount | fixed }}</dd>
            <dt>总数</dt>
          </dl>
          <dl>
            <dd>{{ skuCountNew | fixed }}</dd>
            <dt>本月新增</dt>
          </dl>
        </div>
      </li>
      <li style="--bkgColor: rgb(251,120,138);">
        <h3>福茶码赋码量</h3>
        <div class="flexColumn dataDetail">
          <dl>
            <dd>{{ codeCount | fixed }}</dd>
            <dt>总数</dt>
          </dl>
          <dl>
            <dd>{{ codeCountNew | fixed }}</dd>
            <dt>本月新增</dt>
          </dl>
        </div>
      </li>
    </ul>
    <div class="quickLink">
      <h2>快捷入口</h2>
      <ul class="flexColumn items">
        <li v-for="item in quickLinkItems" :key="item.link" @click="goto(item.link)">
          <svg class="icon-svg linkIcon" aria-hidden="true">
            <use v-bind:xlink:href="`#icon-${item.icon}`"></use>
          </svg>
          <p>{{ item.name }}</p>
        </li>
      </ul>
    </div>
    <div class="chartGroup flexColumn">
      <div class="chartBox">
        <h2>订单交易金额统计</h2>
        <div class="dateBox">
          <el-date-picker
            v-model="saleOrderRange"
            size="small" type="monthrange" value-format="yyyy-MM"
            range-separator="至" start-placeholder="开始月份" end-placeholder="结束月份"
            @change="saleOrderDateChanged">
          </el-date-picker>
        </div>
        <div class="chartContainer" id="saleOrderChart"></div>
      </div>
      <div class="chartBox">
        <h2>商品销售额排行</h2>
        <div class="dateBox">
          <el-date-picker
            v-model="saleTopRange"
            size="small" type="daterange" value-format="yyyy-MM-dd"
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
            @change="saleTopDateChanged">
          </el-date-picker>
        </div>
        <div class="saleTopTable">
          <table border="0" width="100%" class="scrollTable">
            <thead>
              <tr>
                <th class="col00"> </th>
                <th class="col01">商品名称</th>
                <th class="col02">销售数量</th>
                <th class="col03">销售金额</th>
              </tr>
            </thead>
            <tbody>
              <div class="scrollBox">
                <tr v-for="(item, idx) in teaSaleTop" :key="idx" :class="`top${idx}`">
                  <td class="col00">{{ idx + 1 }}</td>
                  <td class="col01">{{ item.name }}（{{ item.specification }}）</td>
                  <td class="col02">{{ item.quantity }} {{ item.unitName }}</td>
                  <td class="col03">{{ item.totalPrice.toFixed(3) }}元</td>
                </tr>
              </div>
            </tbody>
          </table>
        </div>
      </div>
      <div class="chartBox" v-if="false">
        <h2>茶叶库存量</h2>
        <div class="dateBox">
          <el-date-picker
            v-model="range2"
            size="small" type="daterange" value-format="yyyy-MM-dd"
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <div class="chartContainer"></div>
      </div>
    </div>
    <div class="warehouseChart">
      <h2>仓库情况</h2>
      <div class="chartContainer" id="warehouseChartBox"></div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { dateFormat } from '@/lib/utils'
import echarts from 'echarts'

export default {
  data () {
    return {
      saleOrderRange: [],
      range2: [],
      saleTopRange: [],
      quickLinkItems: [{
        name: '新增仓库',
        icon: 'plus-square',
        link: 'addTenant'
      }, {
        name: '新增仓库管理员',
        icon: 'adduser',
        link: 'addTenantAdmin'
      }, {
        name: '新增茶叶品类',
        icon: 'file-add',
        link: 'addTeaCategory'
      }, {
        name: '新增品牌',
        icon: 'trademark',
        link: 'addBrand'
      }]
    }
  },
  computed: {
    ...mapState('home', [
      'ownerCount', 'ownerCountNew',
      'codeCount', 'codeCountNew',
      'skuCount', 'skuCountNew',
      'spuCount', 'spuCountNew',
      'tenantEnabled', 'tenantDisabled',
      'usedVolume', 'remainVolume',
      'saleOrderInfo', 'teaSaleTop', 'warehouseVolume'
    ])
  },
  activated () {
    this.initData()
  },
  filters: {
    fixed (value, fixed = 0) {
      let v = parseFloat(value)
      if (isNaN(v))  return '-'
      else return v.toFixed(fixed)
    }
  },
  methods: {
    ...mapActions('home', [ 'init', 'getTeaSaleTop', 'getSaleOrderInfo' ]),
    initData () {
      let d = new Date()
      d.setDate(-14)
      let today = dateFormat('yyyy-MM-dd')
      let firstDay = dateFormat('yyyy-MM-dd', d)
      this.$set(this, 'range2', [firstDay, today])
      this.$set(this, 'saleTopRange', [firstDay, today])
      let m = new Date()
      m.setMonth(-6)
      let sixMonthsAgo = dateFormat('yyyy-MM', m)
      let thisMonth = dateFormat('yyyy-MM')
      this.$set(this, 'saleOrderRange', [sixMonthsAgo, thisMonth])
      this.init().then(() => {
        this.initWarehouseInfo()
      })
      this.$nextTick(() => {
        this.saleTopDateChanged(this.saleTopRange)
        this.saleOrderDateChanged(this.saleOrderRange)
      })
    },
    initWarehouseInfo () {
      if (!Array.isArray(this.warehouseVolume)) return
      let warehouseName = this.warehouseVolume.map((v) => v.tenantName)
      let warehouseUsed = this.warehouseVolume.map((v) => {
        let total = parseFloat(v.totalVolume)
        let used = parseFloat(v.usedVolume)
        if (used < total || total === 0) return used.toFixed(2)
        else return total.toFixed(2)
      })
      let warehouseRemain = this.warehouseVolume.map((v) => {
        let remain = parseFloat(v.surplusVolume)
        if (remain <= 0) return 0
        else return remain
      })
      let ele = document.getElementById('warehouseChartBox')
      let myChart = echarts.getInstanceByDom(ele)
      if (myChart) {
        myChart.clear()
      } else {
        myChart = echarts.init(ele)
      }
      let option = {
        color: ['rgb(153, 223, 33)', 'rgb(243, 246, 248)'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {
          top: '10%',
          right: '1%',
          orient: 'vertical'
        },
        grid: {
          left: '3%',
          right: '8%',
          bottom: '3%',
          top: '10%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: warehouseName
        },
        yAxis: {
          type: 'value',
          name: 'm³'
        },
        series: [
          {
            name: '已使用',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: warehouseUsed
          }, {
            name: '未使用',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: warehouseRemain
          }
        ]
      }
      myChart.setOption(option)
    },
    saleTopDateChanged (value) {
      if (!Array.isArray(value) || value.length < 2) return
      let [startDay, endDay] = value
      this.getTeaSaleTop({ startDay, endDay })
    },
    saleOrderDateChanged (value) {
      if (!Array.isArray(value) || value.length < 2) return
      let [startMonth, endMonth] = value
      this.getSaleOrderInfo({ startMonth, endMonth }).then(() => {
        this.initSaleOrderInfo()
      })
    },
    initSaleOrderInfo () {
      if (!Array.isArray(this.saleOrderInfo)) return
      let months = this.saleOrderInfo.map((v) => v.month)
      let priceData = this.saleOrderInfo.map((v) => {
        let val = parseFloat(v.totalPrice) / 1e4
        return val.toFixed(3)
      })
      let countData = this.saleOrderInfo.map((v) => v.count)
      let ele = document.getElementById('saleOrderChart')
      let myChart = echarts.getInstanceByDom(ele)
      if (myChart) {
        myChart.clear()
      } else {
        myChart = echarts.init(ele)
      }
      let option = {
        color: ['rgb(0,158,255)', 'rgb(227,167,123)'],
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '2%'
        },
        xAxis: [{
          type: 'category',
          data: months,
          axisPointer: {
            type: 'shadow'
          }
        }],
        yAxis: [{
          type: 'value',
          name: '万元'
        }, {
          type: 'value',
          name: '笔',
          splitLine: {
            show: false
          }
        }],
        series: [{
          name: '交易金额',
          type: 'bar',
          yAxisIndex: 0,
          tooltip: {
            formatter: '{b}<br />{a}：¥{c} 万元'
          },
          data: priceData
        }, {
          name: '订单数量',
          type: 'line',
          yAxisIndex: 1,
          tooltip: {
            formatter: '{b}<br />{a}：{c} 笔'
          },
          data: countData
        }]
      }
      myChart.setOption(option)
    },
    goto (name) {
      switch (name) {
        case 'addTenant':
          this.$router.push({ name: 'tenant-index', query: { showAdd: 1 } })
          break
        case 'addTenantAdmin':
          this.$router.push({ name: 'tenant-admin', query: { showAdd: 1 } })
          break
        case 'addTeaCategory':
          this.$router.push({ name: 'publicManage-teaCategory', query: { showAdd: 1 } })
          break
        case 'addBrand':
          this.$router.push({ name: 'publicManage-brand', query: { showAdd: 1 } })
          break
        default:
          break
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.totalViewPage {
  .flexColumn {
    display: flex;
  }

  .warehouseChart, .chartGroup .chartBox {
    margin: 0;
    padding: 10px;
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    position: relative;

    h2 {
      font-size: 20px;
      font-weight: bolder;
      margin:0;
      margin-bottom: 10px;
    }

    .chartContainer {
      width: 100%;
      height: 300px;
    }
  }

  .warehouseChart {
    margin-top: 16px;
  }

  .chartGroup {
    padding: 0;
    align-content: stretch;
    justify-content: space-between;
    margin: 16px -5px 0;

    .chartBox {
      margin: 0 5px;

      .dateBox {
        position: absolute;
        top: 10px;
        right: 10px;
      }

      .scrollTable {
        border-collapse: collapse;
        th, td {
          font-size: 14px;
          line-height: 1.5em;
          font-weight: 400;
          padding: 4px;
          padding-left: 0;
          max-height: 2em;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: keep-all;
          white-space: nowrap;
          text-align: left;
          &:nth-last-child(1) {
            padding-right: 4px;
          }
        }

        > tbody {
          display: block;
          position: relative;
          overflow: auto;
        }

        & > tbody tr, & > thead {
          display: table;
          width: 100%;
          table-layout: fixed; /* 重要  表格固定算法 */
        }
      }

      .saleTopTable {
        margin-top: 16px;
        thead th {
          font-weight: bold;
          font-size: 16px;
        }

        tbody {
          display: block;
          height: 260px;
          overflow: auto;
          position: relative;
        }

        .col00 {
          width: 5%;
          text-align: center;
        }

        .col01 {
          width: 65%;
          text-align: left;
        }

        .col02, .col03 {
          width: 15%;
          text-align: center;
        }

        .top0, .top1, .top2 {
          .col00 {
            color: red;
            font-weight: bolder;
          }
        }

      }
    }
  }

  .quickLink {
    margin: 0;
    margin-top: 16px;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    position: relative;

    h2 {
      background-color: rgb(249,94,90);
      color: #fff;
      font-size: 15px;
      width: 124px;
      padding: 8px;
      margin: 0;
      text-align: center;
      position: absolute;
      top: 15px;
      left: -30px;
      transform: rotate(315deg);
    }

    .items {
      list-style: none;
      padding: 10px;
      margin: 0;
      margin-left: 60px;
      align-content: stretch;
      justify-content: space-around;

      > li {
        text-align: center;
        padding: 6px 10px;
        cursor: pointer;
        &:hover {
          color: #409EFF;
        }
        .linkIcon {
          font-size: 50px;
        }
        > p {
          margin: 0;
          margin-top: 10px;
          font-size: 16px;
          line-height: 1.5em;
        }
      }
    }
  }

  .dataColumn {
    list-style: none;
    padding: 0;
    align-content: stretch;
    justify-content: space-between;
    margin: 0 -5px;
    .dataDetail {
      align-content: stretch;
      justify-content: space-between;
      margin-top: 10px;
      dl {
        width: 100%;
        padding: 0;
        margin: 0 5px;
        dd, dt {
          padding: 0;
          margin: 0;
          color: #fff;
          text-align: center;
          line-height: 1.5em;
        }
        > dt {
          font-size: 18px;
          font-weight: normal;
          margin-top: 6px;
        }
        > dd {
          font-weight: bolder;
          font-size: 22px;
        }
      }
    }
    > li {
      background-color: var(--bkgColor);
      padding: 15px;
      margin: 0 5px;
      width: 100%;
      border-radius: 5px;

      h3 {
        font-size: 18px;
        color: #fff;
        padding: 0;
        margin: 0;
        font-weight: normal;
      }
    }
  }
}
</style>